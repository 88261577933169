import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Terms from "./style"
export default function PrivacyPolicy() {
  return (
    <Terms>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
            <Terms.Box>
              <Terms.Title as="h2">Privacy Policy</Terms.Title>
            </Terms.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-xxl-8 col-xl-9 col-lg-10">
            <Terms.Content>
              <Terms.Text>
                This Privacy Policy describes how WarrantyWorx ("we," "our," or
                "us") collects, uses, and discloses information when you use the
                WarrantyWorx software ("the Software"). By using the Software,
                you agree to the practices described in this Privacy Policy.
              </Terms.Text>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  {" "}
                  Information We Collect
                </Terms.TitleSmall>
                <Terms.List>
                  <Terms.ListItem>
                    Personal Information: We may collect personal information,
                    such as your name, email address, and contact details when
                    you register an account or use the Software.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Usage Data: We may collect information about your use of the
                    Software, including log files, IP addresses, device
                    information, and pages accessed.
                  </Terms.ListItem>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  {" "}
                  How We Use Your Information
                </Terms.TitleSmall>
                <Terms.List>
                  <Terms.ListItem>
                    Providing Services: We use the collected information to
                    provide and improve the functionality of WarrantyWorx and to
                    deliver the services you request.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Communication: We may use your contact information to
                    communicate with you about updates, changes, or important
                    notices related to WarrantyWorx.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Analytics: We may use third-party analytics tools to
                    understand how users interact with the Software and to
                    improve its performance.
                  </Terms.ListItem>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Data Sharing</Terms.TitleSmall>
                <Terms.Text>
                  We do not sell, trade, or otherwise transfer your personal
                  information to third parties. We may share information with
                  trusted third parties who assist us in operating our website,
                  conducting our business, or servicing you. terms.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Data Security</Terms.TitleSmall>
                <Terms.Text>
                  We implement reasonable security measures to protect your
                  personal information. However, no method of transmission over
                  the internet or electronic storage is 100% secure, and we
                  cannot guarantee absolute security.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Cookies and Tracking Technologies
                </Terms.TitleSmall>
                <Terms.Text>
                  WarrantyWorx may use cookies and similar technologies to
                  enhance your user experience and collect information about how
                  you use the Software.
                </Terms.Text>
              </Terms.ContentBox>
            </Terms.Content>
            <Terms.Content>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Third-Party Links</Terms.TitleSmall>
                <Terms.Text>
                  The Software may contain links to third-party websites. We are
                  not responsible for the privacy practices or content of these
                  third-party sites.
                </Terms.Text>
              </Terms.ContentBox>
            </Terms.Content>
            <Terms.Content>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Changes to This Privacy Policy
                </Terms.TitleSmall>
                <Terms.Text>
                  We reserve the right to update or change this Privacy Policy
                  at any time. The most recent version will be posted on the
                  WarrantyWorx website.
                </Terms.Text>
              </Terms.ContentBox>
            </Terms.Content>
            <Terms.Content>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Contact Information</Terms.TitleSmall>
                <Terms.Text>
                  For questions or concerns regarding this Privacy Policy,
                  please contact us at Kyle@Warranty-Worx.com.
                </Terms.Text>
              </Terms.ContentBox>
            </Terms.Content>
          </Col>
        </Row>
      </Container>
    </Terms>
  )
}
