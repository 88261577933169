import { Link } from "~components"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Images } from "~data"
import Footer from "./style"
import Mainlogo from "./logo-lg.png"
export default function FooterFive() {
  return (
    <Footer backgroundColor="#f3f4f6">
      <Container>
        <Footer.Box pb="25px">
          <Row className="justify-content-center justify-content-lg-between">
            <Col xs="12">
              <Footer.Logo>
                {/* Brand Logo*/}
                <Footer.Box>
                  <Link to="/">
                    <img src={Mainlogo} alt="logo" style={{ width: "240px" }} />
                  </Link>
                </Footer.Box>
              </Footer.Logo>

              {/* <Footer.Text as="p">We’ll help you achieve Your marketing & business goals</Footer.Text> */}
              <Footer.Menu>
                <Footer.MenuItems>
                  <Link to="/privacy">Privacy Policy</Link>
                </Footer.MenuItems>
                <Footer.MenuItems>
                  {" "}
                  <Link to="/terms">Terms and Conditions</Link>{" "}
                </Footer.MenuItems>
                <Footer.MenuItems>
                  <a target="_blank" href="mailto:Kyle@Warranty-Worx.com" >Contact Us</a>
                </Footer.MenuItems>
                {/* <Footer.MenuItems><a href="#">Blog</a></Footer.MenuItems> */}
                <Footer.MenuItems>
                  <Link to="/information-policy">Information Policy</Link>
                </Footer.MenuItems>
              </Footer.Menu>
            </Col>
          </Row>
        </Footer.Box>
        <Footer.Copyright>
          {/* <Footer.SocialShare>
            <Footer.SocialShareItem>
                <a href="#">
                <i className="fab fa-facebook-square" />
                </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
                <a href="#">
                <i className="fab fa-twitter" />
                </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
                <a href="#">
                <i className="fab fa-instagram" />
                </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
                <a href="#">
                <i className="fab fa-linkedin" />
                </a>
            </Footer.SocialShareItem>
        </Footer.SocialShare> */}
          <Footer.CopyrightText>
            © 2023 WarrantyWorx. All Rights Reserved
          </Footer.CopyrightText>
        </Footer.Copyright>
      </Container>
    </Footer>
  )
}
