import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Terms from "./style"
export default function TermsCondition() {
  return (
    <Terms>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
            <Terms.Box>
              <Terms.Title as="h2">Terms &amp; Conditions</Terms.Title>
            </Terms.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-xxl-8 col-xl-9 col-lg-10">
            <Terms.Content>
              <Terms.Text>
                By accessing or using the WarrantyWorx software ("the
                Software"), you agree to be bound by these terms and conditions.
                If you do not agree with any part of these terms, you must not
                use the Software.
              </Terms.Text>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">License</Terms.TitleSmall>
                <Terms.Text>
                  We grant you a non-exclusive, non-transferable license to use
                  WarrantyWorx for the term of the agreement. This license is
                  subject to the terms outlined in this document.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  {" "}
                  Registration and User Account
                </Terms.TitleSmall>
                <Terms.Text>
                  To access certain features of WarrantyWorx, you may be
                  required to register and create an account. You are
                  responsible for maintaining the confidentiality of your
                  account information and are liable for all activities that
                  occur under your account.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Support and Maintenance
                </Terms.TitleSmall>
                <Terms.Text>
                  Support and maintenance services for WarrantyWorx may be
                  available. Details regarding the scope, duration, and fees for
                  such services will be provided separately.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Limitation of Liability
                </Terms.TitleSmall>
                <Terms.Text>
                  In no event shall WarrantyWorx be liable for any direct,
                  indirect, incidental, special, or consequential damages
                  arising out of or in any way connected with the use of the
                  Software.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Termination</Terms.TitleSmall>
                <Terms.Text>
                  We reserve the right to terminate or suspend your access to
                  WarrantyWorx at any time, without notice, for any reason. Upon
                  termination, all licenses granted to you will cease.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Data Privacy</Terms.TitleSmall>
                <Terms.Text>
                  Your use of WarrantyWorx is subject to our Privacy Policy,
                  which can be found. By using WarrantyWorx, you consent to the
                  terms of the Privacy Policy.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Governing Law</Terms.TitleSmall>
                <Terms.Text>
                  These terms and conditions are governed by and construed in
                  accordance with the laws of [your jurisdiction], without
                  regard to its conflict of law principles.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Changes to Terms and Conditions
                </Terms.TitleSmall>
                <Terms.Text>
                  We reserve the right to modify these terms and conditions for
                  WarrantyWorx at any time. Any changes will be effective
                  immediately upon posting on the Software. Your continued use
                  of WarrantyWorx after the posting of changes constitutes your
                  acceptance of such changes.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Contact Information</Terms.TitleSmall>
                <Terms.Text>
                  For questions regarding these terms and conditions for
                  WarrantyWorx, please contact us at Kyle@Warranty-Worx.com.
                </Terms.Text>
              </Terms.ContentBox>
            </Terms.Content>
          </Col>
        </Row>
      </Container>
    </Terms>
  )
}
